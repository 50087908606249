<template>
  <div class="s-skills">
    <div class="s-skills__container v2-container">
      <div class="s-skills__inner">
        <div class="s-skills__left">
          <h2 class="s-skills__title title-secondary">
            Гибкие навыки
          </h2>

          <p class="s-skills__tx text-main">
            Помимо школьной программы наши ученики развивают навыки
            коммуникации, ораторского мастерства, самоанализа, фин.&nbsp;грамотности,
            предпринимательства и&nbsp;многого другого
          </p>

          <button
            class="s-skills__btn m-btn m-btn--primary"
            @click="$store.dispatch('modals/toggleLead2')"
          >
            <span class="s-skills__btn-cont m-btn__content m-btn__content--primary">
              Узнать подробнее
            </span>
          </button>
        </div>

        <div class="s-skills__rigth">
          <div
            class="s-skills__anim anim"
            @mouseleave="side = 'none'"
          >
            <div class="anim__top">
              <p class="anim__tx">
                наведите на сердце
              </p>

              <p class="anim__tx anim__tx--mob">
                нажмите на сердце
              </p>

              <img
                loading="lazy"
                class="anim__cursor"
                src="/v2/school/skills/cursor.svg"
                alt="cursor"
              >
            </div>

            <div class="anim__main">
              <div
                :class="['anim__wrap anim__wrap--before', {
                  'anim__wrap--before--active' : side === 'before',
                  'anim__wrap--before--hidden' : side === 'after'
                }]"
              >
                <img
                  loading="lazy"
                  class="anim__img anim__img--before"
                  src="/v2/school/skills/before.webp"
                  alt="heart"
                >

                <img
                  loading="lazy"
                  :class="['anim__heart-part', {
                    'anim__heart-part--active' : side === 'before'
                  }]"
                  src="/v2/school/skills/before-part.webp"
                  alt="heart part"
                >
              </div>

              <div
                class="anim__trigger anim__trigger--before"
                @mouseenter="side = 'before'"
              />

              <div
                :class="['anim__divider', {
                  'anim__divider--after' : side === 'after',
                  'anim__divider--before' : side === 'before',
                }]"
              />

              <div
                :class="['anim__wrap anim__wrap--after', {
                  'anim__wrap--after--active' : side === 'after',
                  'anim__wrap--after--hidden' : side === 'before'
                }]"
              >
                <img
                  loading="lazy"
                  class="anim__img anim__img--after"
                  src="/v2/school/skills/after.webp"
                  alt="heart"
                >
              </div>

              <div
                class="anim__trigger anim__trigger--after"
                @mouseenter="side = 'after'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolSkills',

  data() {
    return {
      side: 'none',
    };
  },
};
</script>

<style lang="scss" scoped>
.s-skills {
  max-width: 100vw;

  overflow: hidden;

  &__container {
    padding: 0 16px;

    max-width: 1272px;
  }

  &__inner {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    @media only screen and (max-width: $school-desktop) {
      flex-direction: column;
    }
  }

  &__left {
    flex-shrink: 0;

    padding-top: 240px;

    width: 505px;

    @media only screen and (max-width: $school-desktop) {
      padding-top: 0;

      width: auto;
    }
  }

  &__title {
    margin-bottom: 20px;

    letter-spacing: -1.35px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      text-transform: initial;
    }
  }

  &__tx {
    margin-bottom: 40px;

    max-width: 600px;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto 40px;

      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 32px;
    }
  }

  &__btn {
    display: block;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto;
    }
  }

  &__btn-cont {
    padding: 13px 20px 18px 20px;

    background: $violet;

    @include media-down(tablet) {
      padding: 10px 20px 10px 20px;

      font-size: 18px;
    }
  }

  &__rigth {
    width: 100%;

    @media only screen and (max-width: $school-desktop) {
      display: flex;
      justify-content: center;
    }

    @include media-down(tablet) {
      transform: scale(0.7);
    }

    @include media-down(mobile) {
      position: relative;
      top: -88px;

      height: 370px;

      transform: scale(0.5);
    }
  }

  &__anim {
    right: -22px;

    @media only screen and (max-width: $school-desktop) {
      right: auto;
    }
  }
}

.anim {
  position: relative;

  width: 820px;
  height: 682px;

  overflow: hidden;

  @include media-down(laptop) {
    overflow: visible;

    width: 500px;
    right: 160px;
  }

  @include media-down(mobile) {
    right: 260px;

    width: 300px;
  }

  &__top {
    position: absolute;
    top: 55px;
    left: 50%;

    transform: translateX(-50%);

    @include media-down(laptop) {
      left: 410px;

      white-space: nowrap;
    }
  }

  &__tx {
    margin-bottom: 18px;

    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: $black;

    @include media-down(tablet) {
      display: none;
    }

    &--mob {
      display: none;

      @include media-down(tablet) {
        display: block;
      }
    }
  }

  &__cursor {
    display: block;

    margin: 0 auto;
  }

  &__wrap {
    position: absolute;
    z-index: 1;

    width: 840px;
    height: 670px;

    overflow: hidden;

    transition: 0.5s ease-out;

    &--before {
      clip-path: inset(0 50% 0 0);

      &--active {
        clip-path: inset(0 0 0 0);
      }

      &--hidden {
        clip-path: inset(0 100% 0 0);
      }
    }

    &--after {
      clip-path: inset(0 0 0 49%);

      &--active {
        clip-path: inset(0 0 0 0);
      }

      &--hidden {
        clip-path: inset(0 0 0 100%);
      }
    }
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 840px;
    height: 670px;

    object-fit: contain;

    &--before {
      z-index: 1;
    }
  }

  &__heart-part {
    position: relative;
    right: -411px;
    bottom: -74px;

    width: 278px;
    height: 668px;

    object-fit: contain;

    transform: rotate(0deg);
    transform-origin: bottom left;

    transition: 0.5s ease-out;

    &--active {
      transform: rotate(2deg);
    }
  }

  &__trigger {
    position: absolute;
    z-index: 4;
    bottom: 0;

    width: 280px;
    height: 520px;

    overflow: hidden;

    cursor: pointer;

    &--before {
      left: 133px;

      clip-path: polygon(18% 6%, 59% -4%, 115% 22%, 100% 70%, 100% 98%, 67% 84%, 8% 51%, 0 26%);

      opacity: 0;
    }

    &--after {
      left: 414px;

      opacity: 0;

      clip-path: polygon(60% -4%, 108% 25%, 83% 58%, 49% 75%, -5% 100%, -32% 34%, 24% 2%);
    }
  }

  &__divider {
    position: absolute;
    z-index: 2;
    bottom: -13px;
    left: 412px;

    width: 2px;
    height: 527px;

    background-color: $black;

    transition: 0.5s ease-out;

    &--before {
      transform: translateX(420px);
    }

    &--after {
      transform: translateX(-420px);
    }
  }
}
</style>
