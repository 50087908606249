var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s-skills"},[_c('div',{staticClass:"s-skills__container v2-container"},[_c('div',{staticClass:"s-skills__inner"},[_c('div',{staticClass:"s-skills__left"},[_c('h2',{staticClass:"s-skills__title title-secondary"},[_vm._v("\n          Гибкие навыки\n        ")]),_vm._v(" "),_c('p',{staticClass:"s-skills__tx text-main"},[_vm._v("\n          Помимо школьной программы наши ученики развивают навыки\n          коммуникации, ораторского мастерства, самоанализа, фин. грамотности,\n          предпринимательства и многого другого\n        ")]),_vm._v(" "),_c('button',{staticClass:"s-skills__btn m-btn m-btn--primary",on:{"click":function($event){return _vm.$store.dispatch('modals/toggleLead2')}}},[_c('span',{staticClass:"s-skills__btn-cont m-btn__content m-btn__content--primary"},[_vm._v("\n            Узнать подробнее\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"s-skills__rigth"},[_c('div',{staticClass:"s-skills__anim anim",on:{"mouseleave":function($event){_vm.side = 'none'}}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"anim__main"},[_c('div',{class:['anim__wrap anim__wrap--before', {
                'anim__wrap--before--active' : _vm.side === 'before',
                'anim__wrap--before--hidden' : _vm.side === 'after'
              }]},[_c('img',{staticClass:"anim__img anim__img--before",attrs:{"loading":"lazy","src":"/v2/school/skills/before.webp","alt":"heart"}}),_vm._v(" "),_c('img',{class:['anim__heart-part', {
                  'anim__heart-part--active' : _vm.side === 'before'
                }],attrs:{"loading":"lazy","src":"/v2/school/skills/before-part.webp","alt":"heart part"}})]),_vm._v(" "),_c('div',{staticClass:"anim__trigger anim__trigger--before",on:{"mouseenter":function($event){_vm.side = 'before'}}}),_vm._v(" "),_c('div',{class:['anim__divider', {
                'anim__divider--after' : _vm.side === 'after',
                'anim__divider--before' : _vm.side === 'before',
              }]}),_vm._v(" "),_c('div',{class:['anim__wrap anim__wrap--after', {
                'anim__wrap--after--active' : _vm.side === 'after',
                'anim__wrap--after--hidden' : _vm.side === 'before'
              }]},[_c('img',{staticClass:"anim__img anim__img--after",attrs:{"loading":"lazy","src":"/v2/school/skills/after.webp","alt":"heart"}})]),_vm._v(" "),_c('div',{staticClass:"anim__trigger anim__trigger--after",on:{"mouseenter":function($event){_vm.side = 'after'}}})])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anim__top"},[_c('p',{staticClass:"anim__tx"},[_vm._v("\n              наведите на сердце\n            ")]),_vm._v(" "),_c('p',{staticClass:"anim__tx anim__tx--mob"},[_vm._v("\n              нажмите на сердце\n            ")]),_vm._v(" "),_c('img',{staticClass:"anim__cursor",attrs:{"loading":"lazy","src":"/v2/school/skills/cursor.svg","alt":"cursor"}})])
}]

export { render, staticRenderFns }